import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Registration from './pages/Registration';
import Login from './pages/Login';
import { AuthContext } from './helpers/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import PageNotFound from './pages/PageNotFound';
import SQFeetCalc from './pages/SQFeetCalc';
import AdminDashboard from './pages/AdminDashboard';
import Dashboard from './pages/Dashboard';
import InventorySystem from './pages/InventorySystem';
import UserDashboard from './pages/UserDashboard';
import UserManagement from './pages/UserManagement';
import User from './components/User';
import BasicData from './pages/BasicData';
import BasicDataUpdate from './pages/BasicDataUpdate';
import BasicDataInput from './pages/BasicDataInput';
import ItemIn from './pages/ItemIn';
import ItemOut from './pages/ItemOut';
import ItemPrint from './components/ItemPrint';
import Transactions from './pages/Transactions';
import InventoryView from './pages/InventoryView';
import RePrint from './pages/RePrint';
import ChangePassword from './pages/ChangePassword';


function App() {
  const [authState, setAuthState] = useState({
    username: "",
    id: 0,
    status: false,
    admin: false
  });

  return (
    <div className='App'>
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <Router>
          <Switch>
            <ProtectedRoute exact path="/" component={Home} />
            <Route exact path="/registration" component={Registration} />
            <Route exact path="/login" component={Login} />
            <ProtectedRoute exact path="/changepassword/:id" component={ChangePassword} adminRequired={false} />
            <ProtectedRoute exact path="/admin" component={AdminDashboard} adminRequired={true} />
            <ProtectedRoute exact path="/user" component={UserDashboard} adminRequired={false} />
            <ProtectedRoute exact path="/users/:id" component={User} adminRequired={true} />
            <ProtectedRoute exact path="/management" component={UserManagement} />
            <ProtectedRoute exact path="/data" component={BasicData} adminRequired={true} />
            <ProtectedRoute exact path="/dataupdate" component={BasicDataUpdate} adminRequired={true} />
            <ProtectedRoute exact path="/datainput" component={BasicDataInput} adminRequired={true} />
            <ProtectedRoute exact path="/system" component={InventorySystem} adminRequired={false} />
            <ProtectedRoute exact path="/itemin" component={ItemIn} adminRequired={false} />
            <ProtectedRoute exact path="/itemout" component={ItemOut} adminRequired={false} />
            <ProtectedRoute Route exact path="/itemprint/:id" component={ItemPrint} adminRequired={false} />
            <ProtectedRoute exact path="/dashboard" component={Dashboard} adminRequired={true} />
            <ProtectedRoute exact path="/transactions" component={Transactions} adminRequired={true} />
            <ProtectedRoute exact path="/view" component={InventoryView} adminRequired={true} />
            <ProtectedRoute exact path="/reprint" component={RePrint} adminRequired={false} />
            <ProtectedRoute exact path="/calculator" component={SQFeetCalc} adminRequired={false} />
            <Route exact path="*" component={PageNotFound} />
          </Switch>
        </Router>
      </AuthContext.Provider>
    </div>
  );
}

export default App;