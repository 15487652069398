import axios from 'axios';
import { AuthContext } from '../helpers/AuthContext';
import Navbar from '../components/Navbar';
import React, { useEffect, useState } from 'react';

const InventoryView = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetchItems();
    }, []);

    const fetchItems = async () => {
        try {
            const response = await axios.get('https://app.decking123.net/auth/items/all');
            setItems(response.data);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    return (
        <div>
            <Navbar />
            <div className="container mt-4 text-center">
                <h2 className="mb-4">Inventory View</h2>
                <br />
                <br />
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Item ID</th>
                            <th>Item Name</th>
                            <th>Width</th>
                            <th>Thickness</th>
                            <th>Supplier</th>
                            <th>Coat</th>
                            <th>Condition</th>
                            <th>Weight</th>
                            <th>Price</th>
                            <th>Stock</th>
                            <th>User</th>
                            <th>Date</th>
                            {/* <th>QRCode</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => (
                            <tr key={index}>
                                <td>{item.id}</td>
                                <td>{item.ItemName}</td>
                                <td>{item.Width}</td>
                                <td>{item.Thickness}</td>
                                <td>{item.Supplier}</td>
                                <td>{item.Coat}</td>
                                <td>{item.Condition}</td>
                                <td>{item.Weight}</td>
                                <td>{item.Price}</td>
                                <td>{item.ItemIn ? '✔️' : '❌'}</td>
                                <td>{item.User}</td>
                                <td>{item.createdAt}</td>
                                {/* <td>{item.QRCode}</td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default InventoryView;