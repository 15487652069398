import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../helpers/AuthContext';
import Navbar from '../components/Navbar';

const BasicDataInput = () => {
    const [formData, setFormData] = useState({
        name: "",
        width: "",
        thickness: "",
        supplier: "",
        coat: "",
        condition: ""
    });

    const [basicData, setBasicData] = useState([]);

    useEffect(() => {
        fetchBasicData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const fetchBasicData = () => {
        axios.get('https://app.decking123.net/auth/data')
            .then(response => {
                setBasicData(response.data);
            })
            .catch(error => {
                console.error('Error fetching basic data:', error);
            });
    };

    const handleItemNameSubmit = () => {
        const { itemName } = formData;
        axios.post('https://app.decking123.net/auth/data', { ItemName: itemName })
            .then((response) => {
                alert('ItemName submitted successfully:', response.data);
                setFormData({ ...formData, itemName: '' });
                fetchBasicData();
            })
            .catch((error) => {
                if (error.response && error.response.status === 400 && error.response.data.error === "Item already exists") {
                    alert('Item already exists.');
                } else {
                    console.error('Error submitting ItemName:', error);
                }
            });
    };

    const handleWidthSubmit = () => {
        const { width } = formData;
        axios.post('https://app.decking123.net/auth/data', { Width: width })
            .then((response) => {
                alert('Width submitted successfully:', response.data);
                setFormData({ ...formData, width: '' });
                fetchBasicData();
            })
            .catch((error) => {
                if (error.response && error.response.status === 400 && error.response.data.error === "Item already exists") {
                    alert('Item already exists.');
                } else {
                    console.error('Error submitting ItemName:', error);
                }
            });
    };

    const handleThicknessSubmit = () => {
        const { thickness } = formData;
        axios.post('https://app.decking123.net/auth/data', { Thickness: thickness })
            .then((response) => {
                alert('Thickness submitted successfully:', response.data);
                setFormData({ ...formData, thickness: '' });
                fetchBasicData();
            })
            .catch((error) => {
                if (error.response && error.response.status === 400 && error.response.data.error === "Item already exists") {
                    alert('Item already exists.');
                } else {
                    console.error('Error submitting ItemName:', error);
                }
            });
    };
    const handleSupplierSubmit = () => {
        const { supplier } = formData;
        axios.post('https://app.decking123.net/auth/data', { Supplier: supplier })
            .then((response) => {
                alert('Supplier submitted successfully:', response.data);
                setFormData({ ...formData, supplier: '' });
                fetchBasicData();
            })
            .catch((error) => {
                if (error.response && error.response.status === 400 && error.response.data.error === "Item already exists") {
                    alert('Item already exists.');
                } else {
                    console.error('Error submitting ItemName:', error);
                }
            });
    };

    const handleCoatSubmit = () => {
        const { coat } = formData;
        axios.post('https://app.decking123.net/auth/data', { Coat: coat })
            .then((response) => {
                alert('Coat submitted successfully:', response.data);
                setFormData({ ...formData, coat: '' });
                fetchBasicData();
            })
            .catch((error) => {
                if (error.response && error.response.status === 400 && error.response.data.error === "Item already exists") {
                    alert('Item already exists.');
                } else {
                    console.error('Error submitting ItemName:', error);
                }
            });
    };

    const handleConditionSubmit = () => {
        const { condition } = formData;
        axios.post('https://app.decking123.net/auth/data', { Condition: condition })
            .then((response) => {
                alert('Condition submitted successfully:', response.data);
                setFormData({ ...formData, condition: '' });
                fetchBasicData();
            })
            .catch((error) => {
                if (error.response && error.response.status === 400 && error.response.data.error === "Item already exists") {
                    alert('Item already exists.');
                } else {
                    console.error('Error submitting ItemName:', error);
                }
            });
    };

    return (
        <div>
            <Navbar />
            <div className="container my-4">
                <div className="row justify-content-center">
                    <div className="col-md-6 text-center">
                    <h2>Input Basic Data</h2>
                    </div>
                </div>
            <br />
            <br />
            <form>
                <div className="row">
                    {/* Item Name */}
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="itemName" className="form-label">Item Name:</label>
                            <input
                                type="text"
                                id="itemName"
                                name="itemName"
                                value={formData.itemName}
                                onChange={(e) => handleChange(e, 'itemName')}
                                className="form-control"
                            />
                            <button type="button" className="btn btn-primary mt-2" onClick={handleItemNameSubmit}>
                                Submit Name
                            </button>
                        </div>
                    {/* Width */}
                    <div className="col-md-6 mb-3">
                        <label htmlFor="width" className="form-label">Width:</label>
                        <input
                            type="number"
                            id="width"
                            name="width"
                            value={formData.width}
                            onChange={(e) => handleChange(e, 'width')}
                            className="form-control"
                        />
                        <button type="button" className="btn btn-primary mt-2" onClick={handleWidthSubmit}>
                            Submit Width
                        </button>
                    </div>
                </div>
                </div>
            <br />
                <div className="row">
                    {/* Thickness */}
                    <div className="col-md-6 mb-3">
                        <label htmlFor="thickness" className="form-label">Thickness:</label>
                        <input
                            type="number"
                            id="thickness"
                            name="thickness"
                            value={formData.thickness}
                            onChange={(e) => handleChange(e, 'thickness')}
                            className="form-control"
                        />
                        <button type="button" className="btn btn-primary mt-2" onClick={handleThicknessSubmit}>
                            Submit Thickness
                        </button>
                    </div>
                    {/* Supplier */}
                    <div className="col-md-6 mb-3">
                        <label htmlFor="supplier" className="form-label">Supplier:</label>
                        <input
                            type="text"
                            id="supplier"
                            name="supplier"
                            value={formData.supplier}
                            onChange={(e) => handleChange(e, 'supplier')}
                            className="form-control"
                        />
                        <button type="button" className="btn btn-primary mt-2" onClick={handleSupplierSubmit}>
                            Submit Supplier
                        </button>
                    </div>
                    {/* Coat */}
                    <div className="col-md-6 mb-3">
                        <br />
                        <label htmlFor="coat" className="form-label">Coat:</label>
                        <input
                            type="text"
                            id="coat"
                            name="coat"
                            value={formData.coat}
                            onChange={(e) => handleChange(e, 'coat')}
                            className="form-control"
                        />
                        <button type="button" className="btn btn-primary mt-2" onClick={handleCoatSubmit}>
                            Submit Coat
                        </button>
                    </div>
                    {/* Condition */}
                    <div className="col-md-6 mb-3">
                        <br />
                        <label htmlFor="condition" className="form-label">Condition:</label>
                        <input
                            type="text"
                            id="condition"
                            name="condition"
                            value={formData.condition}
                            onChange={(e) => handleChange(e, 'condition')}
                            className="form-control"
                        />
                        <button type="button" className="btn btn-primary mt-2" onClick={handleConditionSubmit}>
                            Submit Condition
                        </button>
                    </div>
                </div>
            </form>
            <br />
            <br />
            <hr></hr>
            <br />
            </div>
            /* <div className="container my-4 text-center">
                <h2>Basic Data Table</h2>
                <br />
                <br />
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Item Name</th>
                            <th>Width</th>
                            <th>Thickness</th>
                            <th>Supplier</th>
                            <th>Coat</th>
                            <th>Condition</th>
                        </tr>
                    </thead>
                    <tbody>
                        {basicData.map((item, index) => (
                            <tr key={index}>
                                <td>{item.ItemName}</td>
                                <td>{item.Width}</td>
                                <td>{item.Thickness}</td>
                                <td>{item.Supplier}</td>
                                <td>{item.Coat}</td>
                                <td>{item.Condition}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div> */
        </div>
      );
};

export default BasicDataInput;