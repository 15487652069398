import React, { useState, useEffect, useContext } from 'react';
import QRCode from 'qrcode.react';
import Navbar from '../components/Navbar';
import { AuthContext } from '../helpers/AuthContext';
import axios from 'axios';
import { useHistory, Link } from 'react-router-dom';

const ItemIn = () => {
    const [formData, setFormData] = useState({
        name: '',
        width: '',
        thickness: '',
        supplier: '',
        coat: '',
        condition: '',
        weight: '',
        pricing: ''
    });

    const { authState } = useContext(AuthContext);
    const history = useHistory();

    const [refreshPage, setRefreshPage] = useState(false);
    const [inventoryData, setInventoryData] = useState([]);
    const [itemNames, setItemNames] = useState([]);
    const [itemWidths, setItemWidths] = useState([]);
    const [itemThicknesses, setItemThicknesses] = useState([]);
    const [itemSuppliers, setItemSuppliers] = useState([]);
    const [itemCoats, setItemCoats] = useState([]);
    const [itemConditions, setItemConditions] = useState([]);
    const [qrcodeValue, setQRCodeValue] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const [inventoryRes, itemNamesRes, itemWidthsRes, itemThicknessesRes, itemSuppliersRes, itemCoatsRes, itemConditionsRes] = await Promise.all([
                axios.get('https://app.decking123.net/auth/inventory/getbasicdata'),
                axios.get('https://app.decking123.net/auth/data/itemNames'),
                axios.get('https://app.decking123.net/auth/data/itemWidths'),
                axios.get('https://app.decking123.net/auth/data/itemThicknesses'),
                axios.get('https://app.decking123.net/auth/data/itemSuppliers'),
                axios.get('https://app.decking123.net/auth/data/itemCoats'),
                axios.get('https://app.decking123.net/auth/data/itemConditions')
            ]);
            setInventoryData(inventoryRes.data);
            setItemNames(itemNamesRes.data);
            setItemWidths(itemWidthsRes.data);
            setItemThicknesses(itemThicknessesRes.data);
            setItemSuppliers(itemSuppliersRes.data);
            setItemCoats(itemCoatsRes.data);
            setItemConditions(itemConditionsRes.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleUpdateData = () => {
        fetchData();
        setRefreshPage(true);
    };

    const handleSubmit = async () => {
        try {
            const { name, width, thickness, supplier, coat, condition, weight, pricing } = formData;

            if (!name || !width || !thickness || !condition || !coat) {
                alert('ItemName, Width, Thickness, Condition, and Coat are required fields.');
                return;
            }

            const requestData = {
                ItemName: name,
                Width: width,
                Thickness: thickness,
                Condition: condition,
                Coat: coat,
                ...(supplier && { Supplier: supplier }),
                ...(weight && { Weight: weight }),
                ...(pricing && { Price: pricing }),
                User: authState.username
            };

            const response = await axios.post('https://app.decking123.net/auth/items/newitem', requestData);

            if (response.status === 400 && response.data && response.data.error === 'Item already exists') {
                alert('Item already exists and cannot be created!');
            } else {
                alert('Item created!');
                history.push(`/system`);
            }
        } catch (error) {
            if (error.response && error.response.status === 400 && error.response.data.error === "Item already exists") {
                alert('Item already exists!');
            } else {
                console.error('Error creating item!');
                alert('Error creating item!', error.response.data.error);
            }
        }
    };

    const handleSubmitPrint = async () => {
        try {
            const { name, width, thickness, supplier, coat, condition, weight, pricing } = formData;

            if (!name || !width || !thickness || !condition || !coat) {
                alert('ItemName, Width, Thickness, Condition, and Coat are required fields.');
                return;
            }

            const requestData = {
                ItemName: name,
                Width: width,
                Thickness: thickness,
                Condition: condition,
                Coat: coat,
                ...(supplier && { Supplier: supplier }),
                ...(weight && { Weight: weight }),
                ...(pricing && { Price: pricing }),
                User: authState.username
            };

            const response = await axios.post('https://app.decking123.net/auth/items/newitem', requestData);

            const newItemId = response.data.itemId;

            if (response.status === 400 && response.data && response.data.error === 'Item already exists') {
                alert('Item already exists and cannot be created!');
            } else {
                history.push(`/itemprint/${newItemId}`);
            }
        } catch (error) {
            if (error.response && error.response.status === 400 && error.response.data.error === "Item already exists") {
                alert('Item already exists!');
            } else {
                console.error('Error creating item!');
                alert('Error creating item!', error.response.data.error);
            }
        }
    };

    return (
        <div>
            <Navbar />
            <div className="container my-4">
                <h2 className="text-center mb-4">Item Input</h2>
                {/* <button type="button" className="btn btn-success mt-2 d-block mx-auto" onClick={handleUpdateData}>
                    Update Data
                </button> */}
                <br />
                <br />
                <br />
                <form className="row g-3" onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className="row">
                        {/* Item Name */}
                        <div className="col-md-6 mb-3">
                            <label htmlFor="name" className="form-label">Item Name:</label>
                            <select
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={(e) => handleChange(e)}
                                className="form-control"
                            >
                                <option value="">Select Item Name</option>
                                {itemNames.map((item, index) => (
                                    <option key={index} value={item.ItemName}>{item.ItemName}</option>
                                ))}
                            </select>
                        </div>
                        {/* Width */}
                        <div className="col-md-6 mb-3">
                            <label htmlFor="width" className="form-label">Width:</label>
                            <select
                                id="width"
                                name="width"
                                value={formData.width}
                                onChange={(e) => handleChange(e)}
                                className="form-control"
                            >
                                <option value="">Select Width</option>
                                {itemWidths.map((item, index) => (
                                    <option key={index} value={item.Width}>{item.Width}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        {/* Thickness */}
                        <div className="col-md-6 mb-3">
                            <label htmlFor="thickness" className="form-label">Thickness:</label>
                            <select
                                id="thickness"
                                name="thickness"
                                value={formData.thickness}
                                onChange={(e) => handleChange(e)}
                                className="form-control"
                            >
                                <option value="">Select Thickness</option>
                                {itemThicknesses.map((item, index) => (
                                    <option key={index} value={item.Thickness}>{item.Thickness}</option>
                                ))}
                            </select>
                        </div>
                        {/* Supplier */}
                        <div className="col-md-6 mb-3">
                            <label htmlFor="supplier" className="form-label">Supplier:</label>
                            <select
                                id="supplier"
                                name="supplier"
                                value={formData.supplier}
                                onChange={(e) => handleChange(e)}
                                className="form-control"
                            >
                                <option value="">Select Supplier</option>
                                {itemSuppliers.map((item, index) => (
                                    <option key={index} value={item.Supplier}>{item.Supplier}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <br></br>
                    {/* Coat */}
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="coat" className="form-label">Coat:</label>
                            <select
                                id="coat"
                                name="coat"
                                value={formData.coat}
                                onChange={(e) => handleChange(e)}
                                className="form-control"
                            >
                                <option value="">Select Coat</option>
                                {itemCoats.map((item, index) => (
                                    <option key={index} value={item.Coat}>{item.Coat}</option>
                                ))}
                            </select>
                        </div>
                        {/* Condition */}
                        <div className="col-md-6 mb-3">
                            <label htmlFor="condition" className="form-label">Condition:</label>
                            <select
                                id="condition"
                                name="condition"
                                value={formData.condition}
                                onChange={(e) => handleChange(e)}
                                className="form-control"
                            >
                                <option value="">Select Condition</option>
                                {itemConditions.map((item, index) => (
                                    <option key={index} value={item.Condition}>{item.Condition}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                        <div className="row">
                            <div className="mb-3 col-6">
                                <label htmlFor="weight" className="form-label">Weight:</label>
                                <input
                                    type="number"
                                    name="weight"
                                    value={formData.weight}
                                    onChange={handleChange}
                                    className="form-control"
                                    placeholder="Enter Weight"
                                />
                            </div>
                            <div className="mb-3 col-6">
                                <label htmlFor="pricing" className="form-label">Price:</label>
                                <input
                                    type="number"
                                    name="pricing"
                                    value={formData.pricing}
                                    onChange={handleChange}
                                    className="form-control"
                                    placeholder="Enter Price"
                                />
                            </div>
                        </div>
                        {/* QR Code Display */}
                        <div className="mb-12 text-center">
                            {qrcodeValue && <QRCode value={qrcodeValue} />}
                        </div>
                    <div className="col-12 text-center">
                        {/* Submit button */}
                        <br />
                        <button type="submit" className="btn btn-primary">Add Item</button>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <button className="btn btn-primary black_button" onClick={handleSubmitPrint}>Add & Print</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ItemIn;