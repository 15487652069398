import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';

const InventorySystem = () => {

  return (
    <div>
        <Navbar />
        <div className="container mt-5">
            <h2 className="text-center">Inventory System</h2>
            <br></br>
            <br></br>
            <br></br>
            <div className="row justify-content-center">
                <div className="col-md-3 mb-3 text-center">
                <Link to="/itemin" className="btn btn-primary btn-lg btn-block black_button">
                    Add Item
                </Link>
                </div>
                <div className="col-md-3 mb-3 text-center">
                <Link to="/itemout" className="btn btn-primary btn-lg btn-block black_button">
                    Item Out
                </Link>
                </div>
            </div>
            {/* <div className="row justify-content-center">
                <div className="col-md-3 mb-3 text-center">
                <Link to="/itemupdate" className="btn btn-primary btn-lg btn-block black_button">
                    Update Item
                </Link>
                </div>
            </div> */}
        </div>
    </div>
  );
};

export default InventorySystem;