import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';

const BasicData = () => {

  return (
    <div>
        <Navbar />
        <div className="container mt-5">
            <h2 className="text-center">Basic Data</h2>
            <br></br>
            <br></br>
            <br></br>
            <div className="row justify-content-center">
                <div className="col-md-3 mb-3 text-center">
                    <Link to="/datainput" className="btn btn-primary btn-lg btn-block black_button">
                        Input Basic Data
                    </Link>
                </div>
                <div className="col-md-3 mb-3 text-center">
                    <Link to="/dataupdate" className="btn btn-primary btn-lg btn-block black_button">
                        Update Basic Data
                    </Link>
                </div>
            </div>
        </div>
    </div>
  );
};

export default BasicData;