import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory, Link } from 'react-router-dom';
import Navbar from '../components/Navbar';

function UserManagement() {
    const [listOfUsers, setListOfUsers] = useState([]);
    const history = useHistory();

    useEffect(() => {
        axios.get("https://app.decking123.net/auth").then((response) => {
            setListOfUsers(response.data);
        });
    }, []);

    const handleUserClick = (userId) => {
        history.push(`/users/${userId}`);
    };

    return (
        <div>
            <Navbar />
            <div className="container mt-5 text-center">
                <h2 className="text-center">User Management</h2>
                <br />
                <Link to="/registration" className="btn btn-primary btn-block black_button col-2">Add User</Link>
                <br />
                <br />
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Username</th>
                            <th>Admin</th>
                            <th>Created At</th>
                            <th>Updated At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listOfUsers.map((user, index) => (
                            <tr key={index} onClick={() => handleUserClick(user.id)}>
                                <td>{user.id}</td>
                                <td>{user.username}</td>
                                <td>{user.admin ? 'Yes' : 'No'}</td>
                                <td>{user.createdAt}</td>
                                <td>{user.updatedAt}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default UserManagement;