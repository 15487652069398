import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from '../helpers/AuthContext';

function Navbar() {
  const { authState, setAuthState } = useContext(AuthContext);
    const history = useHistory();
    const location = useLocation();

    const logout = () => {
        localStorage.removeItem("accessToken");
        setAuthState({
            username: "",
            id: 0,
            status: false,
            admin: false
        });
        history.push("/login");
    };

    const changePassword = () => {
        history.push(`/changepassword/${authState.id}`);
    };

    const redirectToDashboard = () => {
        if (authState.admin) {
            history.push("/admin");
        } else {
            history.push("/user");
        }
    };

    // Function to handle clicking on headerNav
    const handleHeaderNavClick = () => {
        if (!authState.status) {
            // If not logged in, redirect to login page
            history.push("/login");
        } else if (location.pathname === "/") {
            // If on home page, redirect based on authentication status and role
            redirectUser();
        }
    };

    // Function to redirect user based on authentication status and role
    const redirectUser = () => {
        if (authState.admin) {
            // User is logged in as admin, redirect to admin page
            history.push("/admin");
        } else {
            // User is logged in as non-admin user, redirect to user page
            history.push("/user");
        }
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
            <div className="d-flex align-items-center">
                <a className="navbar-brand" id="headerNav" href="/" onClick={redirectUser}>AQEL Inventory App</a>
                {authState.status && (
                    <>
                    <h3 id="navUser" className="ml-0 mb-0">| <span id='navUser2'> {authState.username}</span></h3>
                    </>
                )}
            </div>
                {authState.status && (
                    <>
                        <button className="btn btn-light redirectDashboard" onClick={redirectToDashboard}>Dashboard</button>
                        <div className="btn-group">
                            <button className="btn btn-secondary" onClick={changePassword}>Change Password</button>
                            &nbsp;
                            <button className="btn btn-danger" onClick={logout}>Logout</button>
                        </div>
                    </>
                )}
            </div>
        </nav>
    );
}

export default Navbar;
