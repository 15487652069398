import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../helpers/AuthContext';
import Navbar from '../components/Navbar';

function Login() {

  let history = useHistory();

  const [username, setUserName] = useState("");
  const [password, setUserPassword] = useState("");
  const { setAuthState } = useContext(AuthContext);

  const login = () => {
    const data = { username: username, password: password, admin: false };
    axios.post("https://app.decking123.net/auth/login", data).then((response) => {
        if(response.data.error) {
            alert(response.data.error);
        } else {
            localStorage.setItem("accessToken", response.data.token);
            setAuthState({
              username: response.data.username,
              id: response.data.id,
              status: true,
              admin: response.data.admin
            });
            if(response.data.admin) {
              history.push("/admin");
            } else {
              history.push("/user");
            }
        }
    });
  };

  return (
    <div>
      <Navbar />
      <div className="container my-4">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body text-center">
                <h2 className="card-title">Login</h2><br></br>
                <input
                  type="text"
                  onChange={(event) => {
                      setUserName(event.target.value);
                  }}
                  className="form-control mb-3"
                  placeholder="Username"
                />
                <input
                  type="password"
                  onChange={(event) => {
                      setUserPassword(event.target.value);
                  }}
                  className="form-control mb-3"
                  placeholder="Password"
                />
                <br></br>
                <button onClick={login} className="btn btn-primary col-4">Login</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
