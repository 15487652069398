import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../helpers/AuthContext';
import Navbar from '../components/Navbar';
import jsQR from 'jsqr';
import { useHistory } from 'react-router-dom';


const ItemOut = () => {
    const { authState } = useContext(AuthContext);
    const history = useHistory();
    const [formData, setFormData] = useState({
        id: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        fetchItemDetails(value);
    };

    const [itemId, setItemId] = useState([]);
    const [itemDetails, setItemDetails] = useState({});
    const [scannedData, setScannedData] = useState('');

    useEffect(() => {
        fetchItems();
    }, []);

    // Get Handlers
    // Get Items
    const fetchItems = () => {
        axios.get('https://app.decking123.net/auth/items/allitems')
            .then(response => {
                setItemId(response.data);
            })
            .catch(error => {
                console.error('Error fetching items:', error);
            });
    };

    // Fetch item details based on ID
    const fetchItemDetails = (id) => {
        if (id) {
            axios.get(`https://app.decking123.net/auth/items/item/${id}`)
                .then(response => {
                    setItemDetails(response.data);
                })
                .catch(error => {
                    console.error('Error fetching item details:', error);
                });
        }
    };

        // QR Code Scanner
        const handleScan = () => {
            const video = document.createElement('video');

            navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
                .then(stream => {
                    video.srcObject = stream;
                    video.play();

                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;

                    const scanFrame = () => {
                        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                        const code = jsQR(imageData.data, canvas.width, canvas.height);
                        if (code) {
                            setScannedData(code.data);
                            fetchItemDetails(code.data);
                        }
                        requestAnimationFrame(scanFrame);
                    };

                    requestAnimationFrame(scanFrame);
                })
                .catch(error => {
                    console.error('Error accessing camera:', error);
                });
        };

    // Item Out
        const handleDelete = async (id) => {
            const confirmDelete = window.confirm(`Are you sure you want to use Item Id: ${id}?`);
            if (confirmDelete) {
                try {
                    const response = await axios.put(`https://app.decking123.net/auth/items/itemout/${id}`, {
                        User: authState.username
                    });
                    alert(`Item ${id} used successfully.`);
                    fetchItems();
                    setItemDetails({});
                    history.push(`/system`);
                } catch (error) {
                    alert(`Error using item Id: ${id}`);
                    console.log(error);
                }
            }
        };

    return (
        <div>
            <Navbar />
            <div className="container my-4">
                <div className="row justify-content-center">
                    <div className="col-md-6 text-center">
                        <h2>Item Out</h2>
                    </div>
                </div>
                <br />
                <br />
                <form className="d-flex justify-content-center">
                    <div className="row col-md-3">
                        {/* QR Code Scanner */}
                        <br />
                        <br />
                        <br />
                        {/* Get Item */}
                        <div className="mb-3 text-center">
                            <label htmlFor="itemId" className="form-label"><strong>Coil No.</strong></label>
                            <select
                                id="itemId"
                                name="id"
                                value={formData.id}
                                onChange={handleChange}
                                className="form-control"
                            >
                                <option value="">Select Coil No.</option>
                                {itemId.map((item) => (
                                    <option key={item.id} value={item.id}>{item.id}</option>
                                ))}
                            </select>
                        </div>
                        <div className="text-center">
                            <button onClick={handleScan} className="btn btn-primary">Scan QR Code</button>
                            {scannedData && <p>Scanned Data: {scannedData}</p>}
                        </div>
                    </div>
                </form>
                <br />
                <br />
                {itemDetails && Object.keys(itemDetails).length > 0 && (
                        <form>
                        <div className="row">
                            {/* Item Name */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="itemName" className="form-label">Item Name:</label>
                                <input
                                    type="text"
                                    id="itemName"
                                    name="itemName"
                                    value={itemDetails.ItemName}
                                    className="form-control"
                                    disabled
                                />
                            </div>
                            {/* Width */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="width" className="form-label">Width:</label>
                                <input
                                    type="text"
                                    id="width"
                                    name="width"
                                    value={itemDetails.Width}
                                    className="form-control"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="row">
                            {/* Thickness */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="thickness" className="form-label">Thickness:</label>
                                <input
                                    type="text"
                                    id="thickness"
                                    name="thickness"
                                    value={itemDetails.Thickness}
                                    className="form-control"
                                    disabled
                                />
                            </div>
                            {/* Supplier */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="supplier" className="form-label">Supplier:</label>
                                <input
                                    type="text"
                                    id="supplier"
                                    name="supplier"
                                    value={itemDetails.Supplier}
                                    className="form-control"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="row">
                            {/* Coat */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="coat" className="form-label">Coat:</label>
                                <input
                                    type="text"
                                    id="coat"
                                    name="coat"
                                    value={itemDetails.Coat}
                                    className="form-control"
                                    disabled
                                />
                            </div>
                            {/* Condition */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="condition" className="form-label">Condition:</label>
                                <input
                                    type="text"
                                    id="condition"
                                    name="condition"
                                    value={itemDetails.Condition}
                                    className="form-control"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="row">
                            {/* Weight */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="weight" className="form-label">Weight:</label>
                                <input
                                    type="text"
                                    id="weight"
                                    name="weight"
                                    value={itemDetails.Weight}
                                    className="form-control"
                                    disabled
                                />
                            </div>
                            {/* Price */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="price" className="form-label">Price:</label>
                                <input
                                    type="text"
                                    id="price"
                                    name="price"
                                    value={itemDetails.Price}
                                    className="form-control"
                                    disabled
                                />
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className='text-center'>
                            <button
                                onClick={() => handleDelete(itemDetails.id)}
                                className="btn btn-danger"
                            >
                                Use Coil
                            </button>
                        </div>
                        <br />
                    </form>
                )}
            </div>
        </div>
    );
};

export default ItemOut;