import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import Navbar from './Navbar';
import * as Yup from 'yup';

const passwordValidationSchema = Yup.string()
    .min(5, 'Password must be at least 5 characters')
    .max(20, 'Password must not exceed 20 characters')
    .required('Password is required');

function User() {
    let history = useHistory();
    let { id } = useParams();
    const [userObject, setUserObject] = useState({});
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        username: "",
        password: "",
        admin: false
    });

    useEffect(() => {
        axios.get(`https://app.decking123.net/auth/byId/${id}`, {
            headers: {
                accessToken: localStorage.getItem("accessToken")
            }
        })
        .then((response) => {
            setUserObject(response.data);
            setFormData({
                username: response.data.username,
                password: "",
                admin: response.data.admin
            });
            setLoading(false);
        })
        .catch((error) => {
            console.error("Error fetching user:", error);
            setLoading(false);
        });
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }


    const handleDelete = () => {
        if (window.confirm(`Are you sure you want to delete user: ${userObject.username}?`)) {
        axios.delete(`https://app.decking123.net/auth/${id}`)
            .then(() => {
                alert("User has been Deleted Successfully!")
                history.push("/management");
            })
            .catch((error) => {
                console.error("Error deleting user:", error);
            });
        }
    };

    const handleUpdate = () => {
        axios.put(`https://app.decking123.net/auth/${id}`, formData)
            .then(() => {
                alert("User has been Updated Successfully!")
                history.push("/management");
            })
            .catch((error) => {
                console.error("Error updating user:", error);
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Navbar />
            <div className="container mt-3">
                <h2>Current User Data</h2>
                <br></br>
                <p><strong>Username: </strong>{userObject.username}</p>
                <p><strong>Admin: </strong>{userObject.admin ? 'Yes' : 'No'}</p>
                <p><strong>Created: </strong> {new Date(userObject.createdAt).toLocaleString()}</p>
                <p><strong>Updated: </strong> {new Date(userObject.updatedAt).toLocaleString()}</p>
            </div>
            <div className="container mt-5">
                <h2>Update User</h2>
                <br></br>
                <form>
                    <div className="mb-3">
                        <label htmlFor="username" className="form-label">New Username:</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="admin" className="form-label">Admin:</label>
                        <select
                            id="admin"
                            name="admin"
                            value={formData.admin}
                            onChange={handleChange}
                            className="form-select"
                        >
                            <option value={false}>No</option>
                            <option value={true}>Yes</option>
                        </select>
                    </div>
                    <div>
                        <button type="button" className="btn btn-primary" onClick={handleUpdate}>Update</button>
                        &nbsp; &nbsp; &nbsp;
                        <button type="button" className="btn btn-danger" onClick={handleDelete}>Delete</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default User;
