import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Navbar from '../components/Navbar';
import { useParams } from 'react-router-dom';

function ChangePassword() {
    const { id } = useParams(); // Retrieve id from URL

    const initialValues = {
        oldPassword: "",
        password: "",
        confirmPassword: ""
    };

    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string().required("Old password is required"),
        password: Yup.string().min(5).max(20).required("New password is required"),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    const onSubmit = (values, { setSubmitting }) => {
        axios.put(`https://app.decking123.net/auth/changepassword/${id}`, values)
            .then(response => {
                console.log(response.data);
                alert("Password Changed Successfully!");
                window.location.reload();
            })
            .catch(error => {
                console.error("Error changing password:", error);
                alert("Error Changing Password!");
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <div>
            <Navbar />
            <div className="container mt-5 text-center">
                <h2>Change Password</h2>
                <br />
                <br />
                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                    <Form>
                        <div className="mb-3 col-3 mx-auto">
                            <label htmlFor="oldPassword" className="form-label">Old Password:</label>
                            <Field
                                type="password"
                                id="oldPassword"
                                name="oldPassword"
                                className="form-control"
                            />
                            <ErrorMessage name="oldPassword" component="div" className="error-message" />
                        </div>
                        <div className="mb-3 col-3 mx-auto">
                            <label htmlFor="password" className="form-label">New Password:</label>
                            <Field
                                type="password"
                                id="password"
                                name="password"
                                className="form-control"
                            />
                            <ErrorMessage name="password" component="div" className="error-message" />
                        </div>
                        <div className="mb-3 col-3 mx-auto">
                            <label htmlFor="confirmPassword" className="form-label">Confirm Password:</label>
                            <Field
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                className="form-control"
                            />
                            <ErrorMessage name="confirmPassword" component="div" className="error-message" />
                        </div>
                        <br />
                        <button type="submit" className="btn btn-primary">Change Password</button>
                    </Form>
                </Formik>
            </div>
        </div>
    );
}

export default ChangePassword;
