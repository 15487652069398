import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';

const Dashboard = () => {

  return (
    <div>
        <Navbar />
        <div className="container mt-5">
            <h2 className="text-center">Dashboard</h2>
            <br></br>
            <br></br>
            <br></br>
            <div className="row justify-content-center">
                <div className="col-md-3 mb-3 text-center">
                    <Link to="/view" className="btn btn-primary btn-lg btn-block black_button">
                        Inventory View
                    </Link>
                </div>
                <div className="col-md-3 mb-3 text-center">
                    <Link to="/transactions" className="btn btn-primary btn-lg btn-block black_button">
                        Transactions
                    </Link>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Dashboard;