import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from './Navbar';
import QRCode from 'qrcode.react';
import { ReactToPrint } from 'react-to-print';

function ItemPrint() {
    const { id } = useParams();
    const componentRef = useRef(null);

    const [itemObject, setItemObject] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`https://app.decking123.net/auth/items/item/${id}`, {
            headers: {
                accessToken: localStorage.getItem("accessToken")
            }
        })
        .then((response) => {
            setItemObject(response.data);
            setLoading(false);
        })
        .catch((error) => {
            console.error("Error fetching item:", error);
            setLoading(false);
        });
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className='text-center'>
            <Navbar />
            <br />
            <div className="container mt-3 text-center" ref={componentRef}>
                <br />
                <div className="mt-3">
                    <QRCode value={itemObject.QRCode} />
                </div>
                <br />
                <p><strong className="big-font"># {itemObject.id}</strong></p>
                <p><strong className="average-font">{itemObject.ItemName}</strong></p>
                <p><strong className="medium-font">G {itemObject.Thickness}</strong></p>

            </div>
            <br />
            <div>
              <ReactToPrint
                trigger={() => <button className="btn btn-primary black_button">Print</button>}
                content={() => componentRef.current}
                documentTitle='new document'
                pageStyle="print"
                onAfterPrint={()=>{alert('Printed Successfully!')}}
              />
            </div>
        </div>
    );
}

export default ItemPrint;