import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Navbar from '../components/Navbar';
import { useHistory } from 'react-router-dom';

function Registration() {
  const initialValues = {
    username: "",
    password: "",
    admin: false
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().min(3).max(20).required("You must input a username"),
    password: Yup.string().min(5).max(20).required("You must input a password")
  });

  const history = useHistory();

  const onSubmit = (data) => {
    axios.post("https://app.decking123.net/auth", data).then(() => {
      console.log(data);
      history.push("/admin");
    });
  };

  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body text-center">
                <h2 className="card-title text-center mb-4">Registration</h2>
                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                  <Form>
                    <div className="mb-3">
                      <Field
                        id="inputCreateUser"
                        name="username"
                        placeholder="Enter Username..."
                        className="form-control"
                      />
                      <ErrorMessage name="username" component="span" />
                    </div>
                    <div className="mb-3">
                      <Field
                        type="password"
                        id="inputCreatePassword"
                        name="password"
                        placeholder="Enter Password..."
                        className="form-control"
                      />
                      <ErrorMessage name="password" component="span" />
                    </div>
                    <div className="mb-3 col-6 mx-auto">
                      <label htmlFor="admin" className="form-label">Admin?</label>
                      <Field as="select" id="admin" name="admin" className="form-select">
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </Field>
                    </div>
                    <div className="d-grid gap-2 col-6 mx-auto">
                      <br></br>
                      <button type="submit" className="btn btn-primary black_button">Register</button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registration;