import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../helpers/AuthContext';

const ProtectedRoute = ({ component: Component, adminRequired, ...rest }) => {
  const { authState } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (!authState.status) {
          // User is not logged in, redirect to login page
          return <Redirect to="/login" />;
        }

        if (adminRequired && !authState.admin) {
          // User is not an admin, but admin access is required
          // Redirect to appropriate route for non-admin users
          return <Redirect to="/" />;
        }

        // User is logged in and has access, render the component
        return <Component {...routeProps} />;
      }}
    />
  );
};

export default ProtectedRoute;
