import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../helpers/AuthContext';
import Navbar from '../components/Navbar';

const BasicDataUpdate = () => {
    const [formData, setFormData] = useState({
        name: "",
        width: "",
        thickness: "",
        supplier: "",
        coat: "",
        condition: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const [itemNames, setItemNames] = useState([]);
    const [itemWidths, setItemWidths] = useState([]);
    const [itemThicknesses, setItemThicknesses] = useState([]);
    const [itemSuppliers, setItemSuppliers] = useState([]);
    const [itemCoats, setItemCoats] = useState([]);
    const [itemConditions, setItemConditions] = useState([]);



    useEffect(() => {
        fetchItemNames();
        fetchItemWidths();
        fetchItemThicknesses();
        fetchItemSuppliers();
        fetchItemCoats();
        fetchItemConditions();
    }, []);

    // Get Handlers
    // Get Item Names
    const fetchItemNames = () => {
        axios.get('https://app.decking123.net/auth/data/itemNames')
            .then(response => {
                setItemNames(response.data);
            })
            .catch(error => {
                console.error('Error fetching item names:', error);
            });
    };

    // Get Item Width
    const fetchItemWidths = () => {
        axios.get('https://app.decking123.net/auth/data/itemWidths')
            .then(response => {
                setItemWidths(response.data);
            })
            .catch(error => {
                console.error('Error fetching item Widths:', error);
            });
    };

    // Get Item Thicknesses
    const fetchItemThicknesses = () => {
        axios.get('https://app.decking123.net/auth/data/itemThicknesses')
            .then(response => {
                setItemThicknesses(response.data);
            })
            .catch(error => {
                console.error('Error fetching item Thicknesses:', error);
            });
    };

    // Get Item Suppliers
    const fetchItemSuppliers = () => {
        axios.get('https://app.decking123.net/auth/data/itemSuppliers')
            .then(response => {
                setItemSuppliers(response.data);
            })
            .catch(error => {
                console.error('Error fetching item Suppliers:', error);
            });
    };

    // Get Item Coats
    const fetchItemCoats = () => {
        axios.get('https://app.decking123.net/auth/data/itemCoats')
            .then(response => {
                setItemCoats(response.data);
            })
            .catch(error => {
                console.error('Error fetching item Coats:', error);
            });
    };

    // Get Item Conditions
    const fetchItemConditions = () => {
        axios.get('https://app.decking123.net/auth/data/itemConditions')
            .then(response => {
                setItemConditions(response.data);
            })
            .catch(error => {
                console.error('Error fetching item Conditions:', error);
            });
    };

    // Update Handlers
    // Update ItemName
    const handleItemNameUpdate = (itemName) => {
        const updatedItemName = prompt(`Enter updated value for ${itemName}:`);
        if (updatedItemName) {
            axios.put(`https://app.decking123.net/auth/data/update/itemname/${itemName}`, { ItemName: updatedItemName })
                .then((response) => {
                    alert(`Item ${itemName} updated successfully:`, response.data);
                    fetchItemNames(); // Refresh item names after update
                })
                .catch((error) => {
                    alert(`Error updating ${itemName}:`, error);
                });
        }
    };

    // Update Width
    const handleWidthUpdate = (width) => {
        const updatedWidth = prompt(`Enter updated value for Width:`);
        if (updatedWidth) {
            axios.put(`https://app.decking123.net/auth/data/update/width/${width}`, { Width: updatedWidth })
                .then((response) => {
                    alert(`Width updated successfully:`, response.data);
                    fetchItemWidths();
                })
                .catch((error) => {
                    console.error(`Error updating Width:`, error);
                });
        }
    };

    // Update Thickness
    const handleThicknessUpdate = (thickness) => {
        const updatedThickness = prompt(`Enter updated value for Thickness:`);
        if (updatedThickness) {
            axios.put(`https://app.decking123.net/auth/data/update/thickness/${thickness}`, { Thickness: updatedThickness })
                .then((response) => {
                    alert(`Thickness updated successfully:`, response.data);
                    fetchItemThicknesses();
                })
                .catch((error) => {
                    console.error(`Error updating Thickness:`, error);
                });
        }
    };

    // Update Supplier
    const handleSupplierUpdate = (supplier) => {
        const updatedSupplier = prompt(`Enter updated value for Supplier:`);
        if (updatedSupplier) {
            axios.put(`https://app.decking123.net/auth/data/update/supplier/${supplier}`, { Supplier: updatedSupplier })
                .then((response) => {
                    alert(`Supplier updated successfully:`, response.data);
                    fetchItemSuppliers();
                })
                .catch((error) => {
                    console.error(`Error updating Supplier:`, error);
                });
        }
    };

    // Update Coat
    const handleCoatUpdate = (coat) => {
        const updatedCoat = prompt(`Enter updated value for Coat:`);
        if (updatedCoat) {
            axios.put(`https://app.decking123.net/auth/data/update/coat/${coat}`, { Coat: updatedCoat })
                .then((response) => {
                    alert(`Coat updated successfully:`, response.data);
                    fetchItemCoats();
                })
                .catch((error) => {
                    console.error(`Error updating Coat:`, error);
                });
        }
    };

    // Update Condition
    const handleConditionUpdate = (condition) => {
        const updatedCondition = prompt(`Enter updated value for Condition:`);
        if (updatedCondition) {
            axios.put(`https://app.decking123.net/auth/data/update/condition/${condition}`, { Condition: updatedCondition })
                .then((response) => {
                    alert(`Condition updated successfully:`, response.data);
                    fetchItemConditions();
                })
                .catch((error) => {
                    console.error(`Error updating Condition:`, error);
                });
        }
    };


    // Delete Handlers
    // Delete ItemName
    const handleItemNameDelete = (itemName) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete ${itemName}?`);
        if (confirmDelete) {
            axios.put(`https://app.decking123.net/auth/data/delete/itemname/${itemName}`, { data: { ItemName: itemName } })
                .then((response) => {
                    alert(`Item ${itemName} deleted successfully.`);
                    fetchItemNames(); // Refresh item names after deletion
                })
                .catch((error) => {
                    console.error(`Error deleting ${itemName}:`, error);
                });
        }
    };

    // Delete Width
    const handleWidthDelete = (width) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete Width: ${width}?`);
        if (confirmDelete) {
            axios.put(`https://app.decking123.net/auth/data/delete/width/${width}`)
                .then((response) => {
                    alert(`Width ${width} deleted successfully.`);
                    fetchItemWidths();
                })
                .catch((error) => {
                    console.error(`Error deleting Width:`, error);
                });
        }
    };

    // Delete Thickness
    const handleThicknessDelete = (thickness) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete Thickness: ${thickness}?`);
        if (confirmDelete) {
            axios.put(`https://app.decking123.net/auth/data/delete/thickness/${thickness}`)
                .then((response) => {
                    alert(`Thickness ${thickness} deleted successfully.`);
                    fetchItemThicknesses();
                })
                .catch((error) => {
                    console.error(`Error deleting Thickness:`, error);
                });
        }
    };

    // Delete Supplier
    const handleSupplierDelete = (supplier) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete Supplier: ${supplier}?`);
        if (confirmDelete) {
            axios.put(`https://app.decking123.net/auth/data/delete/supplier/${supplier}`)
                .then((response) => {
                    alert(`Supplier ${supplier} deleted successfully.`);
                    fetchItemSuppliers();
                })
                .catch((error) => {
                    console.error(`Error deleting Supplier:`, error);
                });
        }
    };

    // Delete Coat
    const handleCoatDelete = (coat) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete Coat: ${coat}?`);
        if (confirmDelete) {
            axios.put(`https://app.decking123.net/auth/data/delete/coat/${coat}`)
                .then((response) => {
                    alert(`Coat ${coat} deleted successfully.`);
                    fetchItemCoats();
                })
                .catch((error) => {
                    console.error(`Error deleting Coat:`, error);
                });
        }
    };

    // Delete Condition
    const handleConditionDelete = (condition) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete Condition: ${condition}?`);
        if (confirmDelete) {
            axios.put(`https://app.decking123.net/auth/data/delete/condition/${condition}`)
                .then((response) => {
                    alert(`Condition ${condition} deleted successfully.`);
                    fetchItemConditions();
                })
                .catch((error) => {
                    console.error(`Error deleting Condition:`, error);
                });
        }
    };


    return (
        <div>
            <Navbar />
            <div className="container my-4">
                <div className="row justify-content-center">
                    <div className="col-md-6 text-center">
                        <h2>Update Basic Data</h2>
                    </div>
                </div>
                <br />
                <br />
                <form>
                    <div className="row">
                        {/* Item Name */}
                        <div className="col-md-6 mb-3">
                            <label htmlFor="itemName" className="form-label">Item Name:</label>
                            <select
                                id="itemName"
                                name="itemName"
                                value={formData.itemName}
                                onChange={(e) => handleChange(e)}
                                className="form-control"
                            >
                                <option value="">Select Item Name</option>
                                {itemNames.map((item, index) => (
                                    <option key={index} value={item.ItemName}>{item.ItemName}</option>
                                ))}
                            </select>
                            <button type="button" className="btn btn-success mt-2" onClick={() => handleItemNameUpdate(formData.itemName)}>
                                Update
                            </button>
                            &nbsp;
                            <button type="button" className="btn btn-danger mt-2" onClick={() => handleItemNameDelete(formData.itemName)}>
                                Delete
                            </button>
                        </div>
                        {/* Width */}
                        <div className="col-md-6 mb-3">
                            <label htmlFor="width" className="form-label">Width:</label>
                            <select
                                id="width"
                                name="width"
                                value={formData.width}
                                onChange={(e) => handleChange(e)}
                                className="form-control"
                            >
                                <option value="">Select Width</option>
                                {itemWidths.map((item, index) => (
                                    <option key={index} value={item.Width}>{item.Width}</option>
                                ))}
                            </select>
                            <button type="button" className="btn btn-success mt-2" onClick={() => handleWidthUpdate(formData.width)}>
                                Update
                            </button>
                            &nbsp;
                            <button type="button" className="btn btn-danger mt-2" onClick={() => handleWidthDelete(formData.width)}>
                                Delete
                            </button>
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        {/* Thickness */}
                        <div className="col-md-6 mb-3">
                            <label htmlFor="thickness" className="form-label">Thickness:</label>
                            <select
                                id="thickness"
                                name="thickness"
                                value={formData.thickness}
                                onChange={(e) => handleChange(e)}
                                className="form-control"
                            >
                                <option value="">Select Thickness</option>
                                {itemThicknesses.map((item, index) => (
                                    <option key={index} value={item.Thickness}>{item.Thickness}</option>
                                ))}
                            </select>
                            <button type="button" className="btn btn-success mt-2" onClick={() => handleThicknessUpdate(formData.thickness)}>
                                Update
                            </button>
                            &nbsp;
                            <button type="button" className="btn btn-danger mt-2" onClick={() => handleThicknessDelete(formData.thickness)}>
                                Delete
                            </button>
                        </div>
                        {/* Supplier */}
                        <div className="col-md-6 mb-3">
                            <label htmlFor="supplier" className="form-label">Supplier:</label>
                            <select
                                id="supplier"
                                name="supplier"
                                value={formData.supplier}
                                onChange={(e) => handleChange(e)}
                                className="form-control"
                            >
                                <option value="">Select Supplier</option>
                                {itemSuppliers.map((item, index) => (
                                    <option key={index} value={item.Supplier}>{item.Supplier}</option>
                                ))}
                            </select>
                            <button type="button" className="btn btn-success mt-2" onClick={() => handleSupplierUpdate(formData.supplier)}>
                                Update
                            </button>
                            &nbsp;
                            <button type="button" className="btn btn-danger mt-2" onClick={() => handleSupplierDelete(formData.supplier)}>
                                Delete
                            </button>
                        </div>
                    </div>
                    <br></br>
                    {/* Coat */}
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="coat" className="form-label">Coat:</label>
                            <select
                                id="coat"
                                name="coat"
                                value={formData.coat}
                                onChange={(e) => handleChange(e)}
                                className="form-control"
                            >
                                <option value="">Select Coat</option>
                                {itemCoats.map((item, index) => (
                                    <option key={index} value={item.Coat}>{item.Coat}</option>
                                ))}
                            </select>
                            <button type="button" className="btn btn-success mt-2" onClick={() => handleCoatUpdate(formData.coat)}>
                                Update
                            </button>
                            &nbsp;
                            <button type="button" className="btn btn-danger mt-2" onClick={() => handleCoatDelete(formData.coat)}>
                                Delete
                            </button>
                        </div>
                        {/* Condition */}
                        <div className="col-md-6 mb-3">
                            <label htmlFor="condition" className="form-label">Condition:</label>
                            <select
                                id="condition"
                                name="condition"
                                value={formData.condition}
                                onChange={(e) => handleChange(e)}
                                className="form-control"
                            >
                                <option value="">Select Condition</option>
                                {itemConditions.map((item, index) => (
                                    <option key={index} value={item.Condition}>{item.Condition}</option>
                                ))}
                            </select>
                            <button type="button" className="btn btn-success mt-2" onClick={() => handleConditionUpdate(formData.condition)}>
                                Update
                            </button>
                            &nbsp;
                            <button type="button" className="btn btn-danger mt-2" onClick={() => handleConditionDelete(formData.condition)}>
                                Delete
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BasicDataUpdate;