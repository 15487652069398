import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../helpers/AuthContext';
import Navbar from '../components/Navbar';

function Home() {
    const { authState } = useContext(AuthContext);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Check if already logged in
        if (authState.status) {
            if (authState.admin) {
                // User is logged in as admin, redirect to admin page
                history.push('/admin');
            } else {
                // User is logged in as non-admin user, redirect to user page
                history.push('/user');
            }
        } else {
            // User is not logged in, redirect to login page
            history.push('/login');
        }

        // Set loading state to false after redirecting
        setIsLoading(false);
    }, [authState, history]);

    // Render Navbar only after redirecting is completed
    return (
        <div>
            {!isLoading && <Navbar />}
        </div>
    );
}

export default Home;