import React from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';


function PageNotFound() {
  return (
    <div className='text-center'>
        <Navbar />
        <br></br>
        <h1>Page Not Found</h1>
        <br></br>
        <h3>
            <Link to="/">Home</Link>
        </h3>
    </div>
  )
}

export default PageNotFound